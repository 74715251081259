//フォント設定
@mixin baseFont {
	font-family: "Noto Sans JP", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic", "游ゴシック", YuGothic, "メイリオ", Meiryo,sans-serif;
}
@mixin fontLatin {
	font-family: 'Sen', sans-serif;
}
@mixin fontLight {
	font-weight: 300;
}
@mixin fontNormal {
	font-weight: 400;
}
@mixin fontMedium {
	font-weight: 600;
}
@mixin fontBold {
	font-weight: 700;
}
// レスポンシブ対応（可変）のフォントサイズ（クライアントによって好みが分かれるので仕様は適宜判断）
@function get_vw($size, $viewport:375){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}
@mixin fz_vw($font_size:14){
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}

// 内側のボックスサイズ
@mixin innerBase {
	max-width: 1160px;
	width: 100%;
	margin: 0 auto;
}

// リンク設定
@mixin linkBase {
	text-decoration: none;
	transition: all  0.3s;
	color: $basefontColor;
}
@mixin hoverBase {
	opacity: 0.7;
}
@mixin boxSizing {
	box-sizing: border-box;
}

// ボタン・矢印アイコンベース
@mixin btnBase {
	width: 100%;
	font-weight: bold;
	 a {
	    display: block;
	    border: 2px solid $basefontColor;
	    text-align: center;
	    padding: 17px 0;
	    position: relative;
	 }
	 @media screen and (min-width:769px){
	 	a {
	 		padding: 25px 0;
	 	}
	 }
}
@mixin arrowBase {
	position: absolute;
    left: 0;
    content: "";
    vertical-align: middle;
}
@mixin arrow01Base {
    width: 10px;
    height: 10px;
    border-top: 2px solid $basefontColor;
    border-right: 2px solid $basefontColor;
    transform: rotate(45deg);
}

// フェード設定
@mixin fadeBase {
	opacity: 0;
    transition: all 1.5s;
}
@mixin fadeIn {
	opacity: 1;
}

//見出し
@mixin mds-type01SP {
	position: relative;
	font-size: 8.5vw;
	letter-spacing: 0.1em;
	margin: 0 auto 15px;
	@include fontLatin;
	@include fontMedium;
	.mds-en {
		@include fontLatin;
		color: #453932;
		font-size: 18px;
		margin-left: 15px;
		vertical-align: middle;
	}
	.txt-jp {
		display: block;
		margin-top: 5px;
		font-size: 20px;
		@include baseFont;
		margin-bottom: 10px;
	}
}
	@mixin mds-type01PC {
		font-size:60px;
	margin: 0;
	margin-bottom: 50px;
	.txt-jp {
		font-weight: bold;
		font-size: 40px;
		margin-bottom: 30px;
	}
}

@mixin mds-type02SP {
	font-size: 32px;
	@include fontLatin;
	@include fontLight;
	line-height: 1.3;
	letter-spacing: 0.1em;
	.txt-jp {
	  display: block;
	  margin-top: 10px;
	  font-size: 14px;
	  letter-spacing: 0;
	  font-weight: bold;
	  @include baseFont;
	}
}
	@mixin mds-type02PC {
	font-size: 65px;
    @include fontMedium;
    .txt-jp {
      font-size: 18px;
      font-weight: bold;
    }
}



  @mixin mapSet {
	.area-box {
		background: #fff;
		margin-bottom: 30px;
		.map-area-col {
		display: flex;
		flex-wrap: wrap;
		padding: 0 18px 10px;
		li {
			width: 50%;
			display: flex;
			margin-bottom: 10px;
		}
		.col {
			width: 30px;
			height: 20px;
			margin-right: 10px;
		}
		.txt {
			font-size: 12px;
			margin-top: -3px;
		}
		.col01 {
			.col {
			background: #ebb667;
			}
		}
		.col02 {
			.col {
			background: #D3D369;
			}
		}
		.col03 {
			.col {
			background: #C2B0DB;
			}
		}
		.col04 {
			.col {
			background: #E39D97;
			}
		}
		.col05 {
			.col {
			background: #A9CE93;
			}
		}
		.col06 {
			.col {
			background: #A3C3DD;
			}
		}
		}
		.area-img {
		padding: 60px 18px;
		position: relative;
		background: #fff;
		overflow: hidden;
		.icon-wrapper {
			position: absolute;
			right: 7px;
			bottom: 10px;
			.icon {
			margin-top: 5px;
			width: 30px;
			cursor: pointer;
			}
		}
		}
		.map-icon {
		display: flex;
		flex-wrap: wrap;
		padding: 0 18px 10px;
		li {
			margin-bottom: 10px;
			margin-right: 10px;
			width: calc(100% / 2 - 10px);
			font-size: 12px;
			img {
			vertical-align: middle;
			margin-right: 5px;
			}
		}
		}
	}
	@media screen and (min-width:769px){
		.area-box {
		margin-bottom: 50px;
		.map-area-col {
			padding: 0 56px 40px;
			justify-content: center;
			li {
			width: calc(100% / 6);
			margin-bottom: 0;
			align-items: center;
			}
			.col {
			width: 50px;
			height: 30px;
			}
			.txt {
			margin-top: 0;
			}
		}
		.area-img {
			padding: 20px 95px;
			margin-bottom: 15px;
			.icon-wrapper {
			right: 20px;
			bottom: 30px;
			.icon {
				width: 40px;
			}
			}
		}
		.map-icon {
			padding: 0 56px;
			li {
			margin-bottom: 18px;
			margin-right: 20px;
			width: calc(100% / 10 - 18px);
			padding-left: 2em;
			text-indent: -2em;
			&:nth-child(10n) {
				margin-right: 0;
			}
			img {
				vertical-align: top;
			}
			}
		}
		}
	}
  }

// キーカラー設定
$basefontColor : #4D4D4D;
$mainBlkColor : #4D4D4D;
$pointColor : #FC5912;
$pointColor2 : #FFBF6A;
/* $pointColor3:#ADC365;
$pointColor4:#89aa1d;
$lineColor : #F8F5EC;
$bg2Color:#FAF9ED; */
