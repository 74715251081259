@charset "utf-8";
/* CSS Document */

@import "import.scss";
@media screen and (min-width: 769px) {
}
/* COMMON PARTS-------------------------------------------*/
.contents-box {
  .notfound-txt {
    margin-bottom: 30px;
    @media screen and (min-width: 769px) {
      font-size: 14px;
    }
    a {
      text-decoration: underline;
      color: $pointColor;
    }
  }
  h3 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    @media screen and (min-width: 769px) {
      font-size: 20px;
    }
  }
}